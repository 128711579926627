import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import BannersPage from '../modules/banner/pages';
import BannersEdit from '../modules/banner/pages/edit';
import BannersRegister from '../modules/banner/pages/register';
import ChannelPage from '../modules/channel/pages';
import ChannelEditPage from '../modules/channel/pages/edit';
import CitiesPage from '../modules/city/pages';
import CitiesEditPage from '../modules/city/pages/edit';
import CitiesRegister from '../modules/city/pages/register';
import CuponsPage from '../modules/cupon/pages';
import CuponsRegister from '../modules/cupon/pages/register';
import DeliveryIndex from '../modules/delivery/page';
import NotificationSettings from '../modules/notifications';
import { CashierProvider } from '../modules/order/hooks/useCashier';
import Cashier from '../modules/order/pages/Cashier';
import OrderDetailsPage from '../modules/order/pages/Details';
import OrdersEditPage from '../modules/order/pages/Edit';
import OrdersListPage from '../modules/order/pages/List';
import OrderVoucherPage from '../modules/order/pages/OrderVoucher';
import OrdersProduction from '../modules/order/pages/Production';
import PagePage from '../modules/page/pages';
import PagesEdit from '../modules/page/pages/edit';
import PagesRegister from '../modules/page/pages/register';
import ProductFormProvider from '../modules/product/contexts/ProductFormProvider';
import Categories from '../modules/product/pages/Categories';
import CategoriesEdit from '../modules/product/pages/Categories/edit';
import CategoriesRegister from '../modules/product/pages/Categories/register';
import Products from '../modules/product/pages/Products';
import ProductEdit from '../modules/product/pages/Products/edit';
import ProductsRegister from '../modules/product/pages/Products/register';
import RelatoriesPage from '../modules/relatory/pages';
import DeliveryMethods from '../modules/setting/pages/DeliveryMethods';
import DeliveryCitySettings from '../modules/setting/pages/DeliveryMethods/DeliveryCitySettings';
import DeliveryDistrictSettings from '../modules/setting/pages/DeliveryMethods/DeliveryDistrictSettings';
import InformationsSettingsPage from '../modules/setting/pages/GeneralSettings/InformationsSettings';
import ScheduleSettings from '../modules/setting/pages/GeneralSettings/ScheduleSettings';
import MenuSettingsPage from '../modules/setting/pages/MenuSettings';
import MenuSettingsEditPage from '../modules/setting/pages/MenuSettings/edit';
import PaymentMethods from '../modules/setting/pages/PaymentMethods';
import CieloSettings from '../modules/setting/pages/PaymentMethods/CieloSettings';
import IuguSettings from '../modules/setting/pages/PaymentMethods/IuguSettings';
import MercadoPagoSettings from '../modules/setting/pages/PaymentMethods/MercadopagoSettings';
import PagarmeSettings from '../modules/setting/pages/PaymentMethods/PagarmeSettings';
import PagseguroSettings from '../modules/setting/pages/PaymentMethods/PagseguroSettings';
import PaymentCreditCardSettings from '../modules/setting/pages/PaymentMethods/PaymentCreditCardSettings';
import PaymentDepositSettings from '../modules/setting/pages/PaymentMethods/PaymentDepositSettings';
import PaypalSettings from '../modules/setting/pages/PaymentMethods/PaypalSettings';
import UsersListPage from '../modules/user/pages/List';
import DeliverymensListPage from '../modules/user/pages/ListDeliverymens';
import UsersRegister from '../modules/user/pages/Register';
import DeliverymensRegister from '../modules/user/pages/RegisterDeliverymens';

import OrderCardEdit from '../modules/order/pages/CardEdit';
import ProductsRelatoryPage from '../modules/relatory/pages/products';
import AnalyticsChatPage from '../modules/setting/pages/IntegrationsSettings/AnalyticsChat';
import MakeBasketSettings from '../modules/setting/pages/MakeBasketSettings';
import MakeBasketEdit from '../modules/setting/pages/MakeBasketSettings/edit';
import MakeBasketRegister from '../modules/setting/pages/MakeBasketSettings/register';
import ServicesPage from '../modules/setting/pages/Services';
import ServicesEdit from '../modules/setting/pages/Services/edit';
import ServicesRegister from '../modules/setting/pages/Services/register';
import SetsPage from '../modules/setting/pages/Sets';
import SetsRegister from '../modules/setting/pages/Sets/register';
import SiteSettingsPage from '../modules/setting/pages/Site';
import VideoSettings from '../modules/setting/pages/VideoSettings';
import StoreSignIn from '../modules/store/SignIn';
import StoreSuccess from '../modules/store/Success';
import UsersEdit from '../modules/user/pages/Edit';
import DeliveryMensEdit from '../modules/user/pages/EditDeliverymens';
import SignIn from '../modules/user/pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Route from './Route';

const router = createBrowserRouter([
    { path: '/', element: <SignIn /> },
    { path: '/login', element: <SignIn /> },
    { path: '/dashboard', element: <Route component={Dashboard} isPrivate /> },
    { path: '/banners', element: <Route component={BannersPage} isPrivate /> },
    {
        path: '/banners/register',
        element: <Route component={BannersRegister} isPrivate />
    },
    {
        path: '/banners/edit/:id',
        element: <Route component={BannersEdit} isPrivate />
    },
    {
        path: '/cashier',
        element: (
            <Route
                component={() => (
                    <CashierProvider>
                        <Cashier />
                    </CashierProvider>
                )}
                withoutLayout
                isPrivate
            />
        )
    },
    {
        path: '/categories',
        element: <Route component={Categories} isPrivate />
    },
    {
        path: '/categories/register',
        element: <Route component={CategoriesRegister} isPrivate />
    },
    {
        path: '/categories/edit/:id',
        element: <Route component={CategoriesEdit} isPrivate />
    },
    {
        path: '/delivery/:storeId/:orderId',
        element: <Route component={DeliveryIndex} withoutLayout />
    },
    { path: '/channels', element: <Route component={ChannelPage} isPrivate /> },
    {
        path: '/channels/edit/:id',
        element: <Route component={ChannelEditPage} isPrivate />
    },
    {
        path: '/notifications',
        element: <Route component={NotificationSettings} isPrivate />
    },
    {
        path: '/orders',
        element: <Route component={OrdersListPage} isPrivate />
    },
    {
        path: '/orders/production',
        element: <Route component={OrdersProduction} isPrivate />
    },
    {
        path: '/orders/:id',
        element: <Route component={OrderDetailsPage} isPrivate />
    },
    {
        path: '/orders/:id/card/edit',
        element: <Route component={OrderCardEdit} isPrivate />
    },
    {
        path: '/orders/:id/voucher',
        element: <Route component={OrderVoucherPage} isPrivate />
    },
    {
        path: '/orders/edit/:id',
        element: <Route component={OrdersEditPage} isPrivate />
    },
    { path: '/products', element: <Route component={Products} isPrivate /> },
    {
        path: '/products/register',
        element: (
            <Route
                component={() => (
                    <ProductFormProvider>
                        <ProductsRegister />
                    </ProductFormProvider>
                )}
                isPrivate
            />
        )
    },
    {
        path: '/products/edit/:id',
        element: (
            <Route
                component={() => (
                    <ProductFormProvider>
                        <ProductEdit />
                    </ProductFormProvider>
                )}
                isPrivate
            />
        )
    },
    {
        path: '/relatories',
        element: <Route component={RelatoriesPage} isPrivate />
    },
    {
        path: '/relatories/products',
        element: <Route component={ProductsRelatoryPage} isPrivate />
    },
    {
        path: '/settings/cities',
        element: <Route component={CitiesPage} isPrivate />
    },
    {
        path: '/settings/cities',
        element: <Route component={CitiesPage} isPrivate />
    },
    {
        path: '/settings/cities/register',
        element: <Route component={CitiesRegister} isPrivate />
    },
    {
        path: '/settings/cities/edit/:id',
        element: <Route component={CitiesEditPage} isPrivate />
    },
    {
        path: '/settings/cupons',
        element: <Route component={CuponsPage} isPrivate />
    },
    {
        path: '/settings/cupons/register',
        element: <Route component={CuponsRegister} isPrivate />
    },
    {
        path: '/settings/delivery_methods',
        element: <Route component={DeliveryMethods} isPrivate />
    },
    {
        path: '/settings/delivery_methods/delivery_cities',
        element: <Route component={DeliveryCitySettings} isPrivate />
    },
    {
        path: '/settings/delivery_methods/delivery_districts',
        element: <Route component={DeliveryDistrictSettings} isPrivate />
    },
    {
        path: '/settings/general',
        element: <Route component={InformationsSettingsPage} isPrivate />
    },
    {
        path: '/settings/menu',
        element: <Route component={MenuSettingsPage} isPrivate />
    },
    {
        path: '/settings/menu/:id',
        element: <Route component={MenuSettingsEditPage} isPrivate />
    },
    {
        path: '/settings/pages',
        element: <Route component={PagePage} isPrivate />
    },
    {
        path: '/settings/pages/register',
        element: <Route component={PagesRegister} isPrivate />
    },
    {
        path: '/settings/pages/edit/:id',
        element: <Route component={PagesEdit} isPrivate />
    },
    {
        path: '/settings/payment_methods',
        element: <Route component={PaymentMethods} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card',
        element: <Route component={PaymentCreditCardSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/deposit',
        element: <Route component={PaymentDepositSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/paypal',
        element: <Route component={PaypalSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card/cielo/:id',
        element: <Route component={CieloSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card/mercadopago/:id',
        element: <Route component={MercadoPagoSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card/pagarme/:id',
        element: <Route component={PagarmeSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card/paypal/:id',
        element: <Route component={PaypalSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card/iugu/:id',
        element: <Route component={IuguSettings} isPrivate />
    },
    {
        path: '/settings/payment_methods/credit_card/pagseguro/:id',
        element: <Route component={PagseguroSettings} isPrivate />
    },
    {
        path: '/settings/make_basket',
        element: <Route component={MakeBasketSettings} isPrivate />
    },
    {
        path: '/settings/video',
        element: <Route component={VideoSettings} isPrivate />
    },
    {
        path: '/settings/make_basket/register',
        element: <Route component={MakeBasketRegister} isPrivate />
    },
    {
        path: '/settings/make_basket/edit/:id',
        element: <Route component={MakeBasketEdit} isPrivate />
    },
    {
        path: '/settings/schedules',
        element: <Route component={ScheduleSettings} isPrivate />
    },
    {
        path: '/settings/services',
        element: <Route component={ServicesPage} isPrivate />
    },
    {
        path: '/settings/services/register',
        element: <Route component={ServicesRegister} isPrivate />
    },
    {
        path: '/settings/services/edit/:id',
        element: <Route component={ServicesEdit} isPrivate />
    },
    {
        path: '/settings/sets',
        element: <Route component={SetsPage} isPrivate />
    },
    {
        path: '/settings/sets/register',
        element: <Route component={SetsRegister} isPrivate />
    },
    {
        path: '/settings/site',
        element: <Route component={SiteSettingsPage} isPrivate />
    },
    {
        path: '/settings/integrations',
        element: <Route component={AnalyticsChatPage} isPrivate />
    },
    {
        path: '/settings/users',
        element: <Route component={UsersListPage} isPrivate />
    },
    {
        path: '/settings/users/deliverymens',
        element: <Route component={DeliverymensListPage} isPrivate />
    },
    {
        path: '/settings/users/deliverymens/register',
        element: <Route component={DeliverymensRegister} isPrivate />
    },
    {
        path: '/settings/users/deliverymens/edit/:id',
        element: <Route component={DeliveryMensEdit} isPrivate />
    },
    {
        path: '/settings/users/register',
        element: <Route component={UsersRegister} isPrivate />
    },
    {
        path: '/settings/users/edit/:id',
        element: <Route component={UsersEdit} isPrivate />
    },
    {
        path: '/stores/register',
        element: <Route component={StoreSignIn} withoutLayout />
    },
    {
        path: '/stores/success',
        element: <Route component={StoreSuccess} withoutLayout />
    }
]);

export default router;
