import { Box, Flex, Spinner } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import apiGateway from '../../../../shared/services/apiGateway';

const VideoPreview = ({ urlVideo, setVideoUrl }: any) => {
    const [isLoading, setIsLoading] = useState(true);

    const handleDelete = useCallback(async () => {
        await apiGateway.delete(`/upload/video/${urlVideo.replace('/videos/', '')}`);
        setVideoUrl('');
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 30000);
    }, []);

    if (isLoading) {
        return (
            <Flex width="200px" height="200px" alignItems="center" justifyContent="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        !isLoading && (
            <Flex position="relative">
                <Box
                    p="4px"
                    backgroundColor="pink.500"
                    color="white"
                    borderRadius="2px"
                    position="absolute"
                    top="0px"
                    right="-10px"
                    title="Excluir Vídeo"
                    cursor="pointer"
                    onClick={() => handleDelete()}
                >
                    <FiTrash2 size={16} />
                </Box>
                <iframe
                    src={`https://player.vimeo.com/video${urlVideo.replace(
                        '/videos',
                        ''
                    )}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    width="200"
                    height="200"
                    allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                    title="Vídeo"
                />
            </Flex>
        )
    );
};

export default VideoPreview;
