import { Checkbox, Divider, Flex, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import React, { useEffect, useRef, useState } from 'react';
import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';

const VideoSettings: React.FC = () => {
    const { changeTitle } = useLayout();

    const [videoSettings, setVideoSettings] = useState(null);
    const [isEnableSite, setIsEnableSite] = useState(false);

    const formRef = useRef<FormHandles>(null);

    useEffect(() => {
        changeTitle('Configurações');

        apiGateway.get('/settings/video').then(({ status, data }) => {
            if (status === 200) {
                setVideoSettings(data);
            }
        });
    }, []);

    return (
        videoSettings && (
            <Form ref={formRef as any} onSubmit={null}>
                <Flex
                    width="100%"
                    maxWidth="800px"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontWeight="500"
                    fontSize="14px"
                >
                    <Text mr="16px" fontSize="24px" color="purple.500">
                        Vídeos
                    </Text>
                    <Flex width="100%" flexDirection="column">
                        <Flex width="100%" justifyContent="space-between">
                            <Flex width="100%" justifyContent="space-between" alignItems="center">
                                <Checkbox
                                    variantColor="green"
                                    size="sm"
                                    borderColor="gray.700"
                                    style={{
                                        outline: 'none'
                                    }}
                                    name="is_enable_site"
                                    isChecked={videoSettings.is_enable === true}
                                >
                                    Módulo Ativo
                                </Checkbox>

                                <Flex width="120px">
                                    <Input
                                        width="120px"
                                        label="Armazenamento Máximo (GB)"
                                        name="min_value"
                                        value={videoSettings.max_size_gb}
                                        size="sm"
                                        isReadOnly
                                    />
                                </Flex>

                                <Text>{`Vídeos Salvos: ${videoSettings.num_videos}`}</Text>
                            </Flex>
                        </Flex>
                        <Flex flexDirection="column">
                            <Text>Streaming</Text>
                            <Checkbox
                                variantColor="green"
                                size="sm"
                                borderColor="gray.700"
                                style={{
                                    outline: 'none'
                                }}
                                isChecked={true}
                            >
                                <Text fontWeight="400">{videoSettings.streaming}</Text>
                            </Checkbox>
                        </Flex>
                        <Divider my="2" />
                    </Flex>
                </Flex>
            </Form>
        )
    );
};

export default VideoSettings;
